<template>
  <base-modal
    dom-id="groupedExtrasModal"
    :show="show"
    classes="modal-content-quantity"
    bodyClass="modal-body"
    :title="groupName"
    @close="close">

    <div slot="content">
      <div class="row">
        <template v-for="extra in localExtras">
          <extra-icon :edit-mode="editMode" :extra="extra" @update="onExtraUpdate" :key="extra.extra_id" />
        </template>
      </div>
    </div>

    <div slot="footer">
        <button type="button" class="btn btn-green" data-dismiss="modal">Ok</button>
    </div>

  </base-modal>
</template>
<script>
import BaseModal from './base_modal.vue'
import ExtraIcon from '@/shared/extra_icon_with_quantity.vue'

export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    extras: {
      type: Object,
      required: true
    },
    group: {
      type: Object
    }
  },
  components: { BaseModal, ExtraIcon },
  computed: {
    localExtras() {
      var e = {}
      if (this.group != null) {
        for (var extraId in this.extras) {
          const extra = this.extras[extraId]
          if (extra.group && (extra.group == this.group.name)) {
            e[extraId] = extra
          }
        }
      }
      return e
    },
    show() {
      return this.group != null
    },
    groupName() {
       if (this.group != null) {
         return this.group.name
       } else {
         return ''
       }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onExtraUpdate(extraId, quantity) {
      this.$emit('update', extraId, quantity)
    }
  }
}
</script>

