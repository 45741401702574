<template>
  <div>
    <div :class="wrapperClass">
      <h2 v-if="title" class="sub-head text-center">{{title}}</h2>
      <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
      <template v-if="!loading" name="fade" mode="out-in">
        <visit-warning v-if="showVisitWarning && editMode" :property-id="propertyId" :context="context" />
        <div v-if="!fromCalendar" class="row mt-3">
          <template v-for="extra in localExtras">
            <extra-icon :edit-mode="editMode" :extra="extra" @change="add(extra.extra_id)" :key="extra.extra_id" />
          </template>
          <template v-for="(extraGroup, idx) in groups">
            <extra-group-icon :edit-mode="editMode" :group="extraGroup" @show-group="activeGroup=extraGroup" @change="add" @update="update" :key="idx" />
          </template>
        </div>
        <div v-else class="row mt-3">
           <template v-for="extra in localExtras">
            <extra-icon :edit-mode="editMode" :extra="extra" @change="add(extra.extra_id)" :key="extra.extra_id" />
          </template>
        </div>
      </template>
    </div>
    <p v-if="showDescription" class="font-12 text-center font-bold gray-text mt-3">*Circles in green are saved Extra Services and others are inactives. {{description}}</p>
    <quantity-modal :extra="clickedExtra" @close="clickedExtra = null" @update="update" :min-quantities="savedExtras" />
    <grouped-extras-modal :group="activeGroup" :extras="localExtrasAll" :edit-mode="editMode" @close="activeGroup = null" @update="update" />
  </div>
</template>
<script>
import api from '@/clients/api'
import Vue from 'vue'
import ExtraIcon from './extra_icon.vue'
import ExtraGroupIcon from './extra_group_icon.vue'
import GroupedExtrasModal from '@/modals/grouped_extras_modal.vue'
import QuantityModal from './fake_extra_quantity_modal.vue'
import VisitWarning from './visit_warning.vue'
import Spinner from '@/shared/spinner.vue'

export default {
  props: {
    quantities: {
      type: Object
    },
    allowedExtraIds: {
      type: Array
    },
    canRemoveExtras: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    editMode: {
      type: Boolean,
      required: true
    },
    hideDescription: {
      type: Boolean,
      required: false
    },
    propertyId: {
      type: Number
    },
    savedExtras: {
      type: Object
    },
    showVisitWarning: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Selected Extras'
    },
    wrapperClass: {
      type: String,
      default: 'box-wrapper'
    },
    fromCalendar: {
      type: Boolean,
      default:false
    }
  },
  components: { ExtraIcon, ExtraGroupIcon, GroupedExtrasModal, QuantityModal, VisitWarning, Spinner },
  data() {
    return {
      activeGroup: null,
      allExtras: {},
      clickedExtra: null,
      groups: {},
      loading: false
    }
  },
  computed: {
    customExtraAssignment() {
      return this.context == 'custom-extra-assignment'
    },
    localExtrasAll() {
      var e = {}
      const allowedExtraIds = Object.keys(this.allExtras).filter(e => this.isExtraAllowed(e))
      for (var i = 0; i < allowedExtraIds.length; i++) {
        const extraId = allowedExtraIds[i]
        const extra = this.allExtras[extraId]
        const quantity = this.quantities[extraId]
        e[extraId] = extra
        const add = parseInt(quantity) > 0
        e[extraId].add = add
        if (quantity != null) {
          e[extraId].quantity = parseInt(quantity)
        } else {
          e[extraId].quantity = 0
        }
        //// do not ask quantity when assigning to clients
        if (this.customExtraAssignment) {
          e[extraId].ask_quantity = false
        }
      }
      return e
    },
    localExtras() {
      var e = {}
      for (var extraId in this.localExtrasAll) {
        const extra = this.localExtrasAll[extraId]
        if (this.fromCalendar == false && extra.group) {
          continue
        }
        e[extraId] = extra
      }
      return e
    },
    showDescription() {
      return this.editMode && this.description && !this.loading && !this.hideDescription
    }
  },
  created() {
    this.loadExtras()
  },
  methods: {
    isExtraAllowed(extraId) {
      const extra = this.allExtras[extraId * 1]
      if (this.customExtraAssignment) {
        return true
      }
      if (extra.is_custom) {
        if (this.allowedExtraIds && (this.allowedExtraIds.length > 0)) {
          return this.allowedExtraIds.includes(extraId.toString())
        } else {
          return false
        }
      }
      return true
    },
    onExtraChange(extra) {
      this.add(extra.extra_id)
    },
    add(extraId) {
      var clickedExtra = this.localExtrasAll[extraId]
      this.clickedExtra = {
        add: clickedExtra.add,
        ask_quantity: clickedExtra.ask_quantity,
        extra_id: clickedExtra.extra_id,
        quantity: clickedExtra.quantity,
        group: clickedExtra.group
      }

      // If the selection is from calendar ungroup extra
      if(this.fromCalendar){
        this.clickedExtra.group = null
      }
      var isNewExtra = this.savedExtras[extraId] == null
      if (this.clickedExtra.add) {
        if (this.canRemoveExtras || (isNewExtra)) {
          this.clickedExtra.add = false
          this.clickedExtra.quantity = 0
          this.update(extraId, 0)
        }
        return
      }
      this.clickedExtra.add = true
      this.clickedExtra.quantity = 1
      if (this.clickedExtra.ask_quantity) {
        return
      }
      this.update(extraId, 1)
    },
    loadExtras() {
      this.loading = true
      var api_func = api.getExtras
      if (this.customExtraAssignment) {
        api_func = api.getCustomExtras
      }
      api_func(this.propertyId).then(response => {
        for (var i = 0; i < response.data.length; i++) {
          const extra = response.data[i]
          Vue.set(this.allExtras, extra.extra_id, extra)
          if (extra.group) {
            Vue.set(this.groups, extra.group, { name: extra.group, gray_icon_url: '', white_icon_url: '' })
          }
        }
        for(var extraId in this.quantities) {
          this.update(extraId, this.quantities[extraId])
        }
        this.loading = false
      })
    },
    update(extraId, newQuantity) {
      const extra = this.allExtras[extraId]
      if (extra) {
        if (!this.isExtraAllowed(extraId)) {
          newQuantity = 0
        }
        this.$emit('update', { extraId: extraId, quantity: newQuantity, name: extra.name, perCost: extra.per_cost, includedQty: extra.included_qty, basePrice: extra.size_based_base_price })
        this.clickedExtra = null
      }
    },
  },
  watch: {
    quantities(newQuantities) {
      for(var extraId in newQuantities) {
        this.update(this.allExtras, extraId, newQuantities[extraId])
      }
    }
  }
}
</script>
