<template>
  <div>
    <extras
      :allowed-extra-ids="allowedExtraIds"
      :fromCalendar="true"
      :wrapperClass="wrapperClass"
      :saved-extras="savedExtras"
      :quantities="changedExtras"
      :edit-mode="editMode"
      description="To add an extra service click on inactive circle. The selected extras will be used for your future visits."
      :property-id="propertyId"
      :show-visit-warning="showVisitWarning"
      :context="context"
      :can-remove-extras="canRemoveExtras"
      :hide-description="hideDescription"
      @update="onExtraUpdate"
    ></extras>
    <mode-switcher v-if="canChangeExtras" :type-button="'icon'" :edit-mode="editMode" label="Change extras for this visit" @edit="startEdit" @cancel="cancel" @update="save" :update-text="updateText"></mode-switcher>
  </div>
</template>
<script>
import Vue from 'vue'
import Extras from '@/shared/extras.vue'
import ModeSwitcher from '@/shared/mode_switcher.vue'

export default {
  props: {
    allowedExtraIds: {
      type: Array
    },
    canChangeExtras: {
      type: Boolean,
      required: true
    },
    canRemoveExtras: {
      type: Boolean,
      default: false
    },
    context: {
      type: String
    },
    clientId: {
      type: Number
    },
    hideDescription: {
      type: Boolean
    },
    propertyId: {
      type: Number
    },
    updateText: {
      type: String,
      default: 'Update'
    },
    savedExtras: {
      type: Object,
      default() {
        return {}
      }
    },
    showVisitWarning: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String
    }
  },
  components: {
    Extras,
    ModeSwitcher
  },
  data() {
    return {
      backupExtras: {},
      changedExtras: {},
      changes: {},
      editMode: false
    }
  },
  mounted() {
    if (this.savedExtras) {
      this.saveSelection(this.savedExtras)
    }
  },
  methods: {
    startEdit() {
      this.editMode = true
      this.$parent.$emit('update:edit-mode', true)
    },
    cancel() {
      this.editMode = false
      this.$parent.$emit('update:edit-mode', false)
      this.resetSelection()
    },
    save() {
      this.editMode = false
      this.$emit('save', { changedExtras: this.changedExtras, changes: this.changes })
    },
    saveSelection(extras) {
      for (var extraId in extras) {
        const quantity = extras[extraId]
        this.setExtraQuantity(extraId, quantity)
        this.backupExtras[extraId] = quantity
      }
    },
    resetSelection() {
      for (var extraId in this.changedExtras) {
        var quantity = 0
        if (this.backupExtras[extraId * 1]) {
          quantity = this.backupExtras[extraId * 1]
        }
        this.setExtraQuantity(extraId, quantity)
      }
    },
    updateReservation(params) {
      this.$emit('update:reservation', params)
    },
    onExtraUpdate({ name, extraId, quantity, perCost, includedQty, basePrice }) {
      const existingQty = this.savedExtras[extraId] ? this.savedExtras[extraId] : 0
      this.setExtraQuantity(extraId, quantity)
      this.$emit('update', this.changedExtras)
      this.$emit('update:picked-extras', { extraId: extraId, name: name, quantity: quantity, perCost: perCost, includedQty: includedQty, basePrice: basePrice })
      var action, changedQty
      if (existingQty < quantity) {
        action = 'increase'
        changedQty = quantity - existingQty
      }
      if (quantity < existingQty) {
        action = 'decrease'
        if (quantity > existingQty) {
          changedQty = existingQty - quantity
        } else {
          changedQty = quantity - existingQty
        }
      }
      if (action) {
        this.changes[extraId] = changedQty
      }
    },
    setExtraQuantity(extraId, quantity) {
      Vue.set(this.changedExtras, extraId.toString(),  quantity)
    }
  },
  watch: {
    canChangeExtras(newValue) {
      if (!newValue) {
        this.cancel()
      }
    },
    savedExtras(newSavedExtras) {
      this.saveSelection(newSavedExtras)
    }
  }
}
</script>
