<template>
  <div>
    <Transition name="fade">
      <div v-if="show" class="modal-blur"></div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="show" class="modal fade show" style="display:block">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content modal-content-quantity">
            <div class="modal-header">
              <button type="button" data-dismiss="modal" class="close" @click="close">×</button>
              <h4 class="modal-title">Enter Quantity</h4>
            </div>
            <div class="modal-body">
              <div class="modal-body-cont display-flex">
                <div class="circul-box" @click="dec">
                  <i class="fas fa-minus"></i>
                </div>
                <div class="modal-body-text">{{ quantity }}</div>
                <div class="circul-box" @click="inc">
                  <i class="fas fa-plus"></i>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="onClick">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>

export default {
  props: {
    extra: {
      type: Object
    },
    minQuantities: {
      type: Object
    }
  },
  computed: {
    minQuantity() {
      if (this.minQuantities) {
        return this.minQuantities[this.extra.extra_id]
      }
    },
    show() {
      return this.extra && this.extra.ask_quantity && this.extra.add && (this.extra.group == null)
    },
  },
  data() {
    return {
      quantity: 1,
    }
  },
  methods: {
    dec() {
      if (this.quantity > 1) {
        if (this.minQuantity >= this.quantity) {
          return
        }
        this.quantity -= 1
      }
    },
    inc() {
      this.quantity += 1
    },
    close() {
      this.$emit('close')
      this.quantity = 1
    },
    onClick() {
      this.$emit('update', this.extra.extra_id, this.quantity)
      this.quantity = 1
    }
  },
  watch: {
    extra(newExtra) {
      if (newExtra) {
        this.quantity = newExtra.quantity
      }
    }
  }
}
</script>