<template>
  <div class="col-md-3 col-sm-4 col-6">
    <div class="add-extras-list" @click="onGroupClick">
      <div class="icon">
        <img v-if="showGrayIcon" src="@images/extras/inside-cabinets-gray.png" class="gray-icon">
        <img v-if="showWhiteIcon" src="@images/extras/inside-cabinets-white.png" class="white-icon">
      </div>
      <h5>{{group.name}}</h5>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    group: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showGrayIcon() {
      return true
    },
    showWhiteIcon() {
      return false
    },
  },
  methods: {
    onGroupClick() {
      if (this.editMode) {
        this.$emit('show-group')
      }
    }
  }
}
</script>
