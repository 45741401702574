<template>
  <div v-if="showExtra" class="col-md-3 col-sm-4 col-6">
    <div :data-extra-id="extra.extra_id" :data-ask="extra.ask_quantity" :id="'extra_' + extra.extra_id" class="add-extras-list" :class="{active: added}" @click="onExtraClick">
      <div class="icon">
        <img v-if="showGrayIcon" :src="extra.gray_icon_url" class="gray-icon">
        <img v-if="showWhiteIcon" :src="extra.white_icon_url" class="white-icon">
        <div class="check-circul-white">{{amount}}</div>
      </div>
      <h5>{{extra.name}}</h5>
      <p><span>{{extra.description}}</span></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    extra: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    added() {
      return this.extra.quantity > 0
    },
    amount() {
      return this.extra.quantity > 1 ? this.extra.quantity : ''
    },
    askQuantity() {
      return this.extra.ask_quantity
    },
    quantity() {
      return this.extra.quantity
    },
    showExtra() {
      return this.extra.add || this.editMode
    },
    showGrayIcon() {
      return !this.showWhiteIcon
    },
    showWhiteIcon() {
      return this.added
    }
  },
  methods: {
    onExtraClick() {
      if (this.editMode) {
        this.$emit('change')
      }
    }
  }
}
</script>
