<template>
  <div>
    <div v-if="typeButton == 'text'">
      <div class="row d-flex justify-content-center align-items-center my-2"  v-if="!editMode">
        <button @click.prevent="$emit('edit')" class="btn btn-mini btn-primary" type="button">{{label}}</button>
      </div>
    </div>
    <div v-if="typeButton == 'icon'">
      <div v-if="!editMode">
        <div class="edit-position-absolute">
          <a @click.prevent="$emit('edit')" class="cursor-pointer">
            <img src="@images/edit-icon02.png" width="40">
          </a>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center" v-if="editMode">
      <div class="text-center my-1">
        <button @click.prevent="$emit('update')" class="btn btn-mini btn-green my-0" type="button">{{realUpdateText}}</button>
      </div>
      <div class="text-center my-1">
        <button @click.prevent="$emit('cancel')" class="btn btn-mini btn-gray my-0" type="button">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    typeButton: {
      type: String,
      default: 'text'
    },
    editMode: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    updateText: {
      type: String,
      default: 'Update'
    },
    requestingChanges: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    realUpdateText() {
      if (this.requestingChanges) {
        return 'Request Change'
      }
      return this.updateText
    }
  }
}
</script>
