<template>
  <div v-if="warning" class="row mx-4 red">
    <div class="col-sm-12" v-html="warning"></div>
  </div>
</template>
<script>
export default {
  props: {
    context: {
      type: String,
      required: true
    },
    forAdmins: {
      type: Boolean,
      default: false
    },
    propertyId: {
      type: Number,
      required: true
    }
  },
  computed: {
    warning() {
      if (this.context == 'visit-extras') {
        return 'Please note: updating the extras here, only changes the extras for this visit. If you like to update the extras for every visit at once <a href="'+this.propertyUrl+'">go to the property</a> and update the extras there.'
      }
      if (this.context == 'property-extras') {
       return 'Please note: updating the extras here will change the extras for every visits, except the visits that already has customized extras. If you only want to change the extras for a specific visit go to the calendar and use the visit modal to customize the extras.'
      }
      if (this.context == 'notes') {
        return 'Please note: updating the notes here, only changes the notes for this visit. If you like to update the notes for every visit at once <a href="'+this.propertyUrl+'">go to the property</a> and update the notes there.'
      }
      if (this.context == 'property-notes') {
       return 'Please note: updating the notes here will change the notes for every visits, except the visits that already has customized notes. If you only want to change the notes for a specific visit go to the calendar and use the visit modal to customize the notes.'
      }
      if (this.context == 'visit-property-details') {
        return 'Please note: updating the details here, only changes the details of this visit. If you like to update the details of the property <a href="'+this.propertyUrl+'">go to the property</a>.'
      }
    },
    //// lists the addresses only when there are more than one properties
    propertyUrl() {
      return '/properties/' + this.propertyId
    },
  }
}
</script>
