<template>
  <div v-if="showExtra" class="item col-md-4 col-sm-4 col-6">
    <div class="item-icon">
      <div :id="'extra_' + extra.extra_id" class="add-extras-list" :class="{active: added}" @click="onNestedExtraClick">
        <div class="icon">
          <img v-if="showGrayIcon" :src="extra.gray_icon_url" class="gray-icon">
          <img v-if="showWhiteIcon" :src="extra.white_icon_url" class="white-icon">
          <div class="check-circul-white">{{amount}}</div>
        </div>
        <h5>{{extra.name}}</h5>
        <p><span>{{extra.description}}</span></p>
      </div>
    </div>
    <div v-if="askQuantity" class="modal-body-cont display-flex">
      <div class="circul-box" @click="dec"><i class="fal fa-minus" aria-hidden="true"></i></div>
      <div class="modal-body-text">{{quantity}}</div>
      <div class="circul-box" @click="inc"><i class="fal fa-plus" aria-hidden="true"></i></div>
    </div>
  </div>
</template>
<script>
import ExtraIcon from './extra_icon.vue'
export default {
  extends: ExtraIcon,
  name: 'ExtraIconWithQuantity',
  data() {
    return {
      currentQuantity: 0
    }
  },
  methods: {
    dec() {
      if (this.currentQuantity > 0) {
        this.currentQuantity -= 1
        this.$emit('update', this.extra.extra_id, this.currentQuantity)
      }
    },
    inc() {
      this.currentQuantity += 1
      this.$emit('update', this.extra.extra_id, this.currentQuantity)
    },
    onNestedExtraClick() {
      if (this.editMode) {
        this.$emit('update', this.extra.extra_id, this.currentQuantity > 0 ? 0 : 1)
      }
    }
  },
  watch: {
    extra: {
      handler(extra) {
        this.currentQuantity = extra.quantity
      },
      deep: true
    },
  }
}
</script>
